import { Input } from "reactstrap"
import { optionOrderStatus, tradeStatus } from "../../utils"
import { useEffect, useState } from "react"
import RefreshToast from "../Componant/refreshToast"
import toast from "react-hot-toast"

const CallPutData = (props) => {
   const data = props.data

   return (
      <table className="call-put-table">
         <thead>
            <tr>
               <th></th>
               <th className="call">INDEX</th>
               <th className="put">PUT</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <th>Strike Price</th>
               <td className="call">-----</td>
               <td className="put">{data?.put?.brokerDetail?.tradingsymbol?.slice(-7, -2)}</td>
            </tr>
            <tr>
               <th>Trading Symbol</th>
               <td className="call">{data?.index?.brokerDetail?.tradingsymbol}</td>
               <td className="put">{data?.put?.brokerDetail?.tradingsymbol}</td>
            </tr>
            <tr>
               <th>Expiry</th>
               <td className="call">---</td>
               <td className="put">
                  {data?.put?.brokerDetail?.expiry.slice(0, 2)} {data?.put?.brokerDetail?.expiry?.slice(2, 5)}{" "}
                  {data?.put?.brokerDetail?.expiry.slice(5, 9)}
               </td>
            </tr>
            <tr>
               <th>ltp</th>
               <td className="call">{data?.index?.ltp}</td>
               <td className="put">{data?.put?.ltp}</td>
            </tr>
            <tr>
               <th>Trigger Value</th>
               <td className="call">{data?.index?.triggerValueForBuy}</td>
               <td className="put">{data?.put?.triggerValueForBuy}</td>
            </tr>
            <tr>
               <th>First Target Value</th>
               <td className="call">------</td>
               <td className="put">{data?.put?.firstTargetValue}</td>
            </tr>
            <tr>
               <th>Second Target Value</th>
               <td className="call">------</td>
               <td className="put">{data?.put?.secondTargetValue}</td>
            </tr>
            <tr>
               <th>Day High</th>
               <td className="call">{data?.index?.dayHigh}</td>
               <td className="put">{data?.put?.dayHigh}</td>
            </tr>
            <tr>
               <th>Stop Loss Value</th>
               <td className="call">{data?.index?.stopLossValue}</td>
               <td className="put">{data?.put?.stopLossValue}</td>
            </tr>
            <tr>
               <th>Lot Size</th>
               <td className="call">-----</td>
               <td className="put">{data?.put?.brokerDetail?.lotsize}</td>
            </tr>
            <tr>
               <th>Quantity</th>
               <td className="call">-----</td>
               <td className="put">{data?.put?.quantity}</td>
            </tr>

            <tr>
               <th>Option Status</th>
               <td className="call">------</td>
               <td className="put">{tradeStatus[data.put?.isActive]}</td>
            </tr>
            <tr>
               <th>Order Status</th>
               <td className="call">{optionOrderStatus[data.index?.orderStatus]}</td>
               <td className="put">{optionOrderStatus[data.put?.orderStatus]}</td>
            </tr>
         </tbody>
      </table>
   )
}

export default CallPutData
